<template>
  <div :id="`TASK_LINK_${id}`" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="md" :title="$t('task_predecessor.title_edit')" footer-class="footerClass" 
      no-close-on-backdrop  content-class="shadow"
      @hidden="$emit('update:show', false)">

      <b-alert variant="danger" dismissible v-model="errorShow">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ $t('error.attention_required') }} 
      </b-alert>

      <b-form-group :label="$t('task_predecessor.field.predecessor')" label-for="task-link-predecessor-name">
        <b-form-input id="task-link-predecessor-name" type="text"
            v-model="link.pName" 
            readonly>
          </b-form-input>
      </b-form-group>

      <b-form-group :label="$t('task_predecessor.field.task')" label-for="task-link-task-name">
        <b-form-input id="task-link-task-name" type="text"
            v-model="link.tName" 
            readonly>
          </b-form-input>
      </b-form-group>

      <b-form-group :label="$t('task_predecessor.field.type')" label-for="task-link-type">
        <multiselect v-model="link.type" class="custom-dropdown-options enable-option-icon"
          :max-height="300"
          :options="optionTaskLinkType.map(i => i.key)"
          :custom-label="getLinkTypeOptionLabel"
          :placeholder="''"
          :searchable="false" 
          :allow-empty="true"
          :showLabels="false"
          :disabled="readOnly">
          <template slot="option" slot-scope="props">
            <font-awesome-icon class="selected-option-icon" v-if="link.type == props.option" :icon="['far', 'check']" />
            <span class="option__title">{{ getLinkTypeOptionLabel(props.option) }}</span>
          </template>
        </multiselect>
      </b-form-group>

      <b-form-group>
        <div class="d-flex align-items-center">
          <label for="task-link-tag" class="mr-2">{{$t('task_predecessor.field.lag')}}</label>
          <font-awesome-icon id="predecessor-lag" :icon="['far', 'circle-question']" class="mb-2" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
          <b-popover target="predecessor-lag" triggers="hover" placement="top">
            {{ $t('task_predecessor.field.lag_hint') }}
          </b-popover>
        </div>
        <b-form-input id="task-link-tag" type="number" min="0" step="1" @blur="lagFormat"
          v-model="link.lag"
          :data-vv-as="$t('task_predecessor.field.lag')"
          data-vv-name="link.lag"
          data-vv-delay="500"
          :readonly="readOnly"
        />
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showLagError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('link.lag') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="!readOnly" :disabled="actionProcessing || !isDeletable" size="sm" variant="secondary" @click="linkDelete" style="margin-right: auto">
          {{ $t('button.delete') }}
        </b-button>
        <b-button v-if="!readOnly" :disabled="actionProcessing" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
   
  </div>
</template>

<script>
import { strRandom } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { taskService, templateTaskService
, taskLinkSuccessorService, templateTaskLinkSuccessorService 
} from '@/services';

import { convertDisplayToDuration, extractDurationConversionOpts } from '@/helpers/task-duration-process';
import Multiselect from 'vue-multiselect';

export default {
  name: 'TaskLinkModal',
  components: {
    Multiselect
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    taskId: {
      type: String
    },
    predecessorId: {
      type: String
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalShow: false,
      alertMsg: null,
      id: strRandom(5),
      link: {
        tUuId: null,
        tName: null,
        pUuId: null,
        pName: null,
        type: null,
        lag: null
      },
      optionTaskLinkType: [],
      actionProcessing: false,
      durationConversionOpts: {}
    }
  },
  created() {
    this.populateTaskLinkType();
    this.getDurationConversionOpts();
  },
  watch: {
    show(newValue) {
      if (newValue != this.modalShow) {
        this.resetForm();
        this.modalShow = newValue;
        this.taskLinkGet(this.taskId, this.predecessorId);
      }
    }
  },
  computed: {
    errorShow() {
      return this.alertMsg != null;
    },
    showLagError() {
      return fieldValidateUtil.hasError(this.errors, 'link.lag');
    },
    isDeletable() {
      return this.link.pUuId != null && this.link.tUuId != null;
    }
  },
  methods: {
    resetForm() { 
      this.actionProcessing = false;
      this.alertMsg = null;
      const link = this.link;
      link.tUuId = null;
      link.tName = null;
      link.pUuId = null;
      link.pName = null;
      link.type = null;
      link.lag = null;
    },
    async taskLinkGet(taskId, predecessorId) {
      const service = this.isTemplate? templateTaskService : taskService;
      let data = await service.specificPredecessor(taskId, predecessorId)
      .then(response => {
        return response.data.length > 0? response.data[0] : null;
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });
      if(data) {
        this.digestResponse(data);
      }
    },
    digestResponse(data) {
      const link = this.link;
      link.tUuId = data.tUuId;
      link.tName = data.tName;
      link.type = data.type;
      link.lag = data.lag;
      link.pName = data.pName;
      link.pUuId = data.pUuId;
    },
    async populateTaskLinkType() {
      const service = this.isTemplate? templateTaskService : taskService;
      let list = await service.optionLinkType()
      .then(response => {
        return response;
      })
      .catch(e => {
        this.httpAjaxError(e);
        return [];
      });
      this.optionTaskLinkType.splice(0, this.optionTaskLinkType.length, ...list.map(i => { return { key: i.label, label: this.$t(`task_link_type.${i.label}`)} }));
    },
    lagFormat() {
      if(isNaN(this.link.lag)) {
        this.link.lag = 0;
      }
      const value = parseInt(this.link.lag);
      if(value < 0) {
        this.link.lag = 0;
      } else {
        this.link.lag = value;
      }
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      
      if(!this.link.type) {
        this.errors.add({
          field: 'link.type',
          msg: this.$t('validation.messages.required', [this.$t('task_predecessor.field.type')])
        });
      }
      if(this.link.lag == null) {
        this.errors.add({
          field: 'link.lag',
          msg: this.$t('validation.messages.required', [this.$t('task_predecessor.field.lag')])
        });
      }

      this.$validator.validate().then(valid => {
        if (valid && this.errors.count() < 1) {
          this.taskLinkSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
    },
    async taskLinkSubmit() {
      this.actionProcessing = true;
      const link = this.link;
      const predecessorId = link.pUuId;
      let lag = link.lag;
      if (lag != null) {
        lag = convertDisplayToDuration(`${link.lag}D`, this.durationConversionOpts).value;
      }
      const successor = { 
        uuId: link.tUuId 
        , type: link.type
        , lag
      }
      const service = this.isTemplate? templateTaskLinkSuccessorService : taskLinkSuccessorService;
      let { hasError, errorMsg } = await service.update(predecessorId, [successor])
      .then(() => {
        return {}
      }).catch(e => {
          console.error(e); // eslint-disable-line no-console
          return { hasError: true, errorMsg: this.$t('task.error.failed_to_update_predecessor')}
      });
      if (hasError) {
        this.alertMsg = errorMsg;
        this.scrollToTop();
      } else {
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t(`task_predecessor.update`) });
      }
      this.actionProcessing = false;
    },
    scrollToTop() {
      document.querySelector(`#TASK_LINK_${this.id}`).scrollIntoView();
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
        
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if(feedback.spot) {
          this.alertMsg = this.$t('error.attention_required');
          this.errors.add({
            field: `task_predecessor.${feedback.spot}`,
            msg: this.$t(`error.${feedback.clue}`, feedback.args)
          })
        } else {
          this.alertMsg = this.$t('error.internal_server');
        }
      } else {
        this.alertMsg = this.$t('error.internal_server');
      }
      this.scrollToTop();
    },
    async linkDelete() {
      this.actionProcessing = true;
      const link = this.link;
      const predecessorId = link.pUuId;
      const successor = { 
        uuId: link.tUuId 
        , type: link.type
        , lag: link.lag
      }
      const service = this.isTemplate? templateTaskLinkSuccessorService : taskLinkSuccessorService;
      let { hasError, errorMsg } = await service.remove(predecessorId, [successor])
      .then(() => {
        return {};
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        return { hasError: true, errorMsg: this.$t('task_predecessor.error.failed_to_delete_predecessor')}
      });
      if (hasError) {
        this.alertMsg = errorMsg;
        this.scrollToTop();
      } else {
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t(`task_predecessor.update`) });
      }
      this.actionProcessing = false;
    },
    getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    getLinkTypeOptionLabel(value) {
      return this.optionTaskLinkType.find(i => i.key === value)?.label || value;
    }
  }
}
</script>
